import React from 'react';
import Menu from './../Menu/menu';
import Home from './../Home/Home';
import Contacts from '../Contacts/MainContacts.js';
import Domaine from './../Domaines_Interventions/Domaines.js'; // Pour le menu je suis obligé d'importer Domaine.js (PS : Je suis débutant avec ce framework peut-être qu'il y a une autre solution mais malheureusement au moment ou j'écris ce commentaire il me reste plus beaucoup de temps et j'ai d'autres travaux pour l'université)
import {Route, BrowserRouter} from 'react-router-dom';
import MainDomaine from './MainDomaines.js';
function Domaines() {
    
    return (
        <div>
            <Menu/>
            <BrowserRouter>      {/* Pour naviguer dans le site */}
                <Route exact path="/" component={Home}/>
                <Route path="/domaine" component={Domaine}/>
                <Route path="/contacts" component={Contacts}/>
            </BrowserRouter>
           <MainDomaine/>

        </div>
          
    )
}


export default Domaines;