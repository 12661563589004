import React  from 'react';
import Header from './header';
import Main from './main';
import './../../sass/Page1.scss';




function Home() {
   
    return (
        <div id="Home">
            <Header/>
            <Main/>  
        </div>
   
    )
}

export default Home;


