/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./../../sass/Page2.scss";
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
//import {gsap} from 'gsap';

function MainDomaines() {

  const pow = Math.pow;

    // The easing function that makes the scroll decelerate over time
    function easeOutQuart(x) {
    return 1 - pow(1 - x, 4);
    }

  const getElementPosition = (element) => element.getBoundingClientRect().top + document.documentElement.scrollTop;

    function animateScroll({ targetPosition, initialPosition, duration }) {
        let start;
        let position;
        let animationFrame;
      
        const requestAnimationFrame = window.requestAnimationFrame;
        const cancelAnimationFrame = window.cancelAnimationFrame;
      
        // maximum amount of pixels we can scroll
        const maxAvailableScroll =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight;
      
        const amountOfPixelsToScroll = initialPosition - targetPosition;
      
        function step(timestamp) {
          if (start === undefined) {
            start = timestamp;
          }
      
          const elapsed = timestamp - start;
      
          // this just gives us a number between 0 (start) and 1 (end)
          const relativeProgress = elapsed / duration;
      
          // ease out that number
          const easedProgress = easeOutQuart(relativeProgress);
      
          // calculate new position for every thick of the requesAnimationFrame
          position =
            initialPosition - amountOfPixelsToScroll * Math.min(easedProgress, 1);
      
          // set the scrollbar position
          window.scrollTo(0, position);
      
          // Stop when max scroll is reached
          if (
            initialPosition !== maxAvailableScroll &&
            window.scrollY === maxAvailableScroll
          ) {
            cancelAnimationFrame(animationFrame);
            return;
          }
      
          // repeat until the end is reached
          if (elapsed < duration) {
            animationFrame = requestAnimationFrame(step);
          }
        }
      
        animationFrame = requestAnimationFrame(step);
      }


    function scrollDown(id,  duration = 1500) {

      if (window.matchMedia("(max-width: 800px)").matches) {
        
        const element = document.getElementById(`${id}`);

        if (!element) {
            // log error if the reference passed is invalid
            return;
        }
        if(element.classList.contains('active')){
          document.querySelectorAll('section.Domaines-content1').forEach(elementAutre => {
            elementAutre.classList.remove('active');
          })
        } else {
          document.querySelectorAll('section.Domaines-content1').forEach(elementAutre => {
            elementAutre.classList.remove('active');
          })
          element.classList.add('active');
        }

      } else {
        // the position of the scroll bar before the user clicks the button
        const initialPosition = window.scrollY;

        const element = document.getElementById(`${id}`);

        if (!element) {
            // log error if the reference passed is invalid
            return;
        }

        animateScroll({
          targetPosition: getElementPosition(element),
          initialPosition,
          duration
        });
      }
        
      };



  return (
    <>
    <Helmet>
        <title>Lexmovice Avocat - Les domaines d'intervention</title>
        <link rel="canonical" href={"https://lexmoviceavocat.fr/domaines"} />
    </Helmet>
    <div id="container" className="container-domaines">
      <section id='Domaines-cards'>
        <h1>Les domaines d'interventions de Lexmovice Avocat : </h1>
        <p className="mobile">Cliquer sur une icone pour découvrir chaque compétence</p>
        <section id="Premiere_colonne_Domaines">
          <article>
            <div className="icon" onClick={() => scrollDown('urbanisme')}>
               <img alt="Urbanisme" id="Urbanisme" src="./img/Maison-bleu.png" />
               <img alt="Urbanisme" id="Urbanisme" src="./img/Maison-blanc.png" />
            </div>
            <p>Urbanisme</p>
          </article>
          <article>
            <div className="icon" onClick={() => scrollDown('administrative')}>
                <img alt="Admin" id="Administrative" src="./img/Admin-Bleu.png" />
                <img alt="Admin" id="Administrative" src="./img/Admin-Blanc.png" />
            </div>
            <p>Responsabilité administrative</p>
          </article>
          <article>
            <div className="icon" onClick={() => scrollDown('domanialite')}>
               <img alt="Urbanisme" id="Domanialité" src="./img/Camion_Bleu.png" />
               <img alt="Urbanisme" id="Domanialité" src="./img/Camion_Blanc.png" />
            </div>
            <p>Domanialité publique</p>
          </article>
          <article>
            <div className="icon" onClick={() => scrollDown('fonction')}>
            <img
              alt="Fonction publique"
              id="Marianne"
              src="./img/Marianne-bleu.png"
            />
            <img
              alt="Fonction publique"
              id="Marianne"
              src="./img/Marianne-blanc.png"
            />
            </div>
            <p>Fonction publique</p>
          </article>
          <article>
            <div className="icon" onClick={() => scrollDown('police')}>
            <img alt="Police administrative" id="Police" src="./img/Police-bleu.png" />
            <img alt="Police administrative" id="Police" src="./img/Police-blanc.png" />
            </div>
            <p>Police administrative</p>
          </article>
          <article>
            <div className="icon" onClick={() => scrollDown('dalo')}>
            <img alt="Droit au logement" id="Dalo" src="./img/dalo-bleu.png" />
            <img alt="Droit au logement" id="Dalo" src="./img/dalo-blanc.png" />
            </div>
            <p>Droit au logement opposable</p>
          </article>
        </section>
      </section>
    </div>
    <section className="Domaines-content1" id="urbanisme" style={{paddingBottom:"0px"}}>
        <div className="container">
            <div className="icon-text">
               <img src="./img/Maison-bleu.png" alt="Urbanisme" />
               <h2>Urbanisme</h2>
            </div>
            <h3>a. En demande :</h3>
            <ul className="domaines-list">
                <li>
                    Demande de <strong>modification du classement</strong> d’une parcelle d’une zone à une autre (ex : le basculement
                    de zonage agricole à urbain pour étendre les possibilités de construction).
                </li>
                <li>
                    Recours contre les <strong>décisions d’urbanisme</strong> (ex : la contestation de la légalité d’un permis de construire
                    accordé à un voisin en raison de sa hauteur excessive et de son implantation proche).
                </li>
                <li>
                    Recours contre un <strong>plan local d’urbanisme</strong> (ex : en ce qu’il grève une parcelle de servitudes
                    d’urbanisme contraignantes).
                </li>
                <li>
                    <strong>Référé-suspension</strong> (ex : en cas de démarrage des travaux).
                </li>
                <li>
                    <strong>Responsabilité de la commune</strong> (ex : refus illégal de permis de construire ou délivrance
                    d’un <b>permis de construire</b> entaché d’illégalité).
                </li>
            </ul>
            </div>
        <div className="container" style={{background:"white",padding:"1vh 5vw 5vh 5vw"}}>
            <h3>b. En défense :</h3>
            <ul className="domaines-list">
                <li>
                   Analyse juridique de la <b>légalité des autorisations d’urbanisme</b> (ex : analyse d’un arrêté de déclaration
                   préalable de travaux pour vérifier qu’il soit exempt de critiques).
                </li>
                <li>
                   Instruction des <b>demandes d'autorisation d'urbanisme</b> (en délégation).
                </li>
                <li>
                   Rédaction de mémoire en défense (ex : en cas de litige et afin de <b>défendre un projet immobilier</b>).
                </li>
                <li>
                   Rédaction de mémoire en dommages et intérêts pour <b>recours abusif</b> (ex : pour les voisins malveillants
                   ou quérulants).
                </li>
            </ul>
            </div>
    </section>

    <section className="Domaines-content1 ciel" id="administrative">
        <div className="container">
            <div className="icon-text">
               <img src="./img/Admin-Bleu.png" alt="Urbanisme" />
               <h2>Responsabilité administrative</h2>
            </div>
            <h3>a. Pour faute :</h3>
            <ul className="domaines-list">
                <li>
                   En <strong>matière hospitalière</strong> (ex : faute commise dans l’organisation du service, défaut d’information du patient)
                </li>
                <li>
                   En <strong>matière carcérale</strong> (ex : dommages causés aux biens d’un détenu par l’administration pénitentiaire.
                </li>
            </ul>
            <h3>b. Sans faute :</h3>
            <ul className="domaines-list">
                <li>
                   Préjudices subis en raison d’une <strong>action/inaction de l’administration</strong> (ex : demande préalable indemnitaire
                   pour dommages de travaux publics).
                </li>
                <li>
                    Préjudices subis en <strong>matière médicale</strong> (ex : indemnisation des préjudices subis en raison d’une exposition à
                    des substances toxiques ou à raison de la défaillance d’un produit/appareil de santé).
                </li>
                <li>
                    Préjudices subis du fait de la <strong>rupture d’égalité</strong> devant les charges publiques (ex : perte d’exploitation
                    occasionnée par une restriction de circulation engendrant un tarissement de la clientèle).
                </li>
            </ul>
            </div>
    </section>

    <section className="Domaines-content1 Domaines-content2" id="domanialite">
        <div className="container">
            <div className="icon-text">
               <img src="./img/Camion_Bleu.png" alt="Urbanisme" />
               <h2>Domanialité publique</h2>
            </div>
            <ul className="domaines-list">
                <li>
                   Contentieux de <strong>l’occupation du domaine public</strong> (ex : autorisation et convention d’occupation temporaire
                   du domaine public, concession de plage et sous-traités d’exploitation, paiement de la redevance d’occupation, occupation sans titre ou éviction illégale du domaine public).
                </li>
                <li>
                   Contentieux de <strong>l’expropriation pour cause d’utilité publique</strong> (ex : contestation de l’arrêté déclarant un projet
                   d’utilité publique ou de l’arrêté de cessibilité).
                </li>
                <li>
                   Contentieux des <strong>dommages de travaux publics</strong> (ex : réparation des préjudices subis à la suite d’une chute
                   causée par un défaut de signalisation des travaux).
                </li>
                <li>
                   Contentieux des <strong>dommages permanents causés par le voisinage d’ouvrages publics</strong> (ex : nuisances
                   sonores ou olfactives émanant d’une station d’épuration, préjudice commercial occasionné par l’allongement de parcours, défaut d’entretien normal de l’ouvrage public).
                </li>
                <li>
                   Contentieux des <strong>aisances de voirie</strong> (ex : atteinte au droit d’accès des riverains à leur propriété).
                </li>
                <li>
                   Contentieux des <strong>contraventions de grande voirie</strong> (ex : pollution des eaux portuaires par des hydrocarbures).
                </li>
            </ul>
            </div>
    </section>

    <section className="Domaines-content1 pont" id="fonction">
        <div className="container">
            <div className="icon-text">
               <img src="./img/Marianne-bleu.png" alt="Urbanisme" />
               <h2>Fonction publique</h2>
            </div>
            <ul className="domaines-list">
                <li>
                    Contentieux des <strong>sanctions disciplinaires</strong> (ex : recours contre un arrêté portant exclusion temporaire
                    d’un agent hors du service public).
                </li>
                <li>
                    Contentieux de la <strong>santé au travail</strong> (ex : référé-expertise en vue d’obtenir la réparation des préjudices
                    issus de la pathologie imputable au service).
                </li>
                <li>
                    Contentieux des <strong>conditions de travail</strong> (ex : harcèlement moral ou sexuel subi dans le cadre du service).
                </li>
                <li>
                    Contentieux du <strong>déroulement de la carrière de l’agent</strong> (ex : non-renouvellement d’un CDD, succession
                    abusive de CDD).
                </li>
            </ul>
            </div>
    </section>

    <section className="Domaines-content1 Domaines-content2" id="police">
        <div className="container">
            <div className="icon-text">
               <img src="./img/Police-bleu.png" alt="Urbanisme" />
               <h2>Police administrative</h2>
            </div>
            <ul className="domaines-list">
                <li>
                   Contentieux des <strong>débits de boissons</strong> (ex : contestation d’un arrêté préfectoral portant fermeture
                   administrative d’un restaurant ou d’un bar).
                </li>
                <li>
                  Contentieux des <strong>libertés publiques</strong> (ex : contestation d’un arrêté municipal restreignant l’exercice
                  de la liberté de circulation).
                </li>
            </ul>
            </div>
    </section>

    <section className="Domaines-content1 hdv" id="dalo">
        <div className="container">
            <div className="icon-text">
               <img src="./img/dalo-bleu.png" alt="Droit au logement" />
               <h2>Droit au logement opposable (DALO)</h2>
            </div>
            <ul className="domaines-list">
                <li style={{width:'80%'}}>
                  Contentieux du <strong>droit au logement opposable</strong> (ex: recours contre les décisions préfectorales refusant l'effectivité du DALO, en dépit de la reconnaissance du caractère prioritaire de la demande faite par une <b>personne dépourvue de logement</b>).
                </li>
            </ul>
            </div>
    </section>

    <footer>
      <hr></hr>
      <p>Lexmovice Avocat - Tous droits réservés</p>
      <p><Link to="/mentions-legales">Mentions légales</Link></p>
      <p>Une création de <a href="http://paullavergne.fr/" target="_blank" rel="noreferrer">Paul Lavergne</a> et <a href="https://margintoniks.fr" target="_blank" rel="noreferrer">Margintoniks</a></p> {/* À passer en ligne <a>quand la page en question sera crée */}
    </footer>
    </>
  );
}

export default MainDomaines;
