import React from 'react';
import './../../sass/Page3.scss';
import Menu from './../Menu/menu';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
//import {gsap} from 'gsap';




function MainContacts() {



    return (
        <div id="container-contacts">
            <Menu/>
            <Helmet>
                <title>Lexmovice Avocat - Contacts</title>
                <link rel="canonical" href={"https://lexmoviceavocat.fr/contacts"} />
            </Helmet>
            <section id="who">
                <section id="Description">
                    <article id="Title">
                        <h1>Contacts</h1>
                    </article>
                    <article>
                        <h2>Coordonnées :</h2>
                        <p><span>Adresse :</span> 19 Boulevard Victor Hugo, 87000 LIMOGES</p>
                        <p><span>Courriel :</span> <a href="mailto:ngrangeravocat@gmx.fr">ngrangeravocat@gmx.fr</a></p>
                        <p><span>Téléphone :</span> <a href="tel:0619518552">06-19-51-85-52</a></p>
                    </article>
                </section>
                <section id="Photo">
                    <img alt="Nicolas Granger" src="img/profil2.jpg"/>
                    <figure>Nicolas GRANGER, <strong>avocat au barreau de Limoges</strong></figure>
                    <p>Intervention géographique en priorité devant les <strong>Tribunaux administratifs de Limoges</strong> et <strong>Bordeaux</strong>, puis à titre subsidiaire ceux de <strong>Poitiers et Nantes</strong>.</p>
                </section>
            </section>
            <section id="TV">
                <section id="Transports">
                    <article id="Titre_Transports">
                        <h2>Infos pratiques :</h2>
                        <h3>Transports :</h3>
                    </article>
                    <article> 
                        <article>
                            <img alt="Lexmovice avocat" src="img/voiture.png"/><p>Place Winston Churchill à proximité immédiate au Nord, avec bus et parc de stationnement</p>
                        </article>
                    </article>
                </section>
            
            <section id="Lieu">
                    <article id="Title_lieu">
                        <h2>Google Map :</h2>
                    </article>
                    <section id="GMap-GStreet">
                        <article id="GoogleMap">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2779.997044711149!2d1.252529215815565!3d45.83134141689058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f934b1c45d734f%3A0xfbed2bec65016293!2s19%20Bd%20Victor%20Hugo%2C%2087000%20Limoges!5e0!3m2!1sfr!2sfr!4v1666782518398!5m2!1sfr!2sfr" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </article>
                        <br/>
                        <article id="GoogleStreetView">
                            <img alt="Lexmovice avocat" src="img/streetvue3d-limoges.jpg"/>
                        </article>
                    </section> 
                    <footer>
                        <hr></hr>
                        <p>Lexmovice Avocat - Tous droits réservés</p>
                        <p><Link to="/mentions-legales">Mentions légales</Link></p>
                        <p>Une création de <a href="http://paullavergne.fr/" target="_blank" rel="noreferrer">Paul Lavergne</a> et <a href="https://margintoniks.fr" target="_blank" rel="noreferrer">Margintoniks</a></p> {/* À passer en ligne <a>quand la page en question sera crée */}
                    </footer>  
                </section>
            </section>
        </div>
          
    )
}


export default MainContacts;
