import Home from './../Home/Home.js';
import Domaines from './../Domaines_Interventions/Domaines.js';
import MainContacts from '../Contacts/MainContacts.js';
import MainLegales from './../legales/mentions.js';
import Honoraires from '../Honoraires';
import ScrollToTop from './ScrollToTop.js';
import './../../sass/App.scss';
import React from 'react';
import {Route, BrowserRouter} from 'react-router-dom';

function App() {

  return (
    <BrowserRouter>
        <ScrollToTop />
        <Route exact path="/" component={Home}/>
        <Route path="/domaines" component={Domaines}/>
        <Route path="/contacts" component={MainContacts}/>
        <Route path="/honoraires" component={Honoraires}/>
        <Route path="/mentions-legales" component={MainLegales}/>
    </BrowserRouter>
  );
}

export default App;
