/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./../../sass/Page2.scss";
import "./../../sass/menu.scss";
import Menu from '../Menu/menu';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
//import {gsap} from 'gsap';

function Honoraires() {
  return (
    <><Menu />
    <Helmet>
        <title>Lexmovice Avocat - Les honoraires & formules</title>
        <link rel="canonical" href={"https://lexmoviceavocat.fr/honoraires"} />
    </Helmet>
    <section className="Honoraires Domaines-content1 Domaines-content2" style={{paddingBottom:'0px'}}>
        
        <div className="container">
          <h2>Les honoraires</h2>
        </div>

    </section>
    <section className='formulas'>
            <div className='container'>
              <h2>FORMULES</h2>
              <div className='formolus-wrapper'>
                <div className='formulus-item'>
                  <img src='./img/Formule_Epine-sanshover.png' alt='Formule_Epine-avec-hover' />
                  <img src='./img/Formule_Epine-avec-hover.png' alt='Formule_Epine-avec-hover' />
                </div>
                <div className='formulus-item'>
                  <img src='./img/Formule_Echarde-sans-hover.png' alt='Formule_Epine-avec-hover' />
                  <img src='./img/Formule_Echarde-avec-hover.png' alt='Formule_Epine-avec-hover' />
                </div>
                <div className='formulus-item'>
                  <img src='./img/Formule_Eclat-sans-hover.png' alt='Formule_Epine-avec-hover' />
                  <img src='./img/Formule_Eclat-hover.png' alt='Formule_Epine-avec-hover' />
                </div>
                <div className='formulus-item'>
                  <img src='./img/Formule_Esquisse-sans-hover.png' alt='Formule_Epine-avec-hover' />
                  <img src='./img/Formule_Esquisse-hover.png' alt='Formule_Epine-avec-hover' />
                </div>
                <div className='formulus-item'>
                  <img src='./img/Formule_Esquive-hover.png' alt='Formule_Epine-avec-hover' />
                  <img src='./img/Formule_Esquive-sans-hover.png' alt='Formule_Epine-avec-hover' />
                </div>
                <div className='formulus-item'>
                  <img src='./img/Formule_Escrime-sans-hover.png' alt='Formule_Epine-avec-hover' />
                  <img src='./img/Formule_Escrime-hover.png' alt='Formule_Epine-avec-hover' />
                </div>
                <div className='formulus-item'>
                  <img src='./img/Formule_Ecarlate-sans-hover.png' alt='Formule_Epine-avec-hover' />
                  <img src='./img/Formule_Ecarlate-hover.png' alt='Formule_Epine-avec-hover' />
                </div>
              </div>
            </div>
            <p id="ttc">Tous les montants indiqués le sont en TTC</p>
          </section>
          <section className="Domaines-content1 Domaines-content2 no-absolute" style={{paddingTop:'0px'}}>
        <div className="container">
          <p>Pour la <strong>formule écarlate</strong> et à titre d’exemple, la prestation inclurait en <strong>urbanisme</strong> :</p>
          <ul className='domaines-list'>
            <li>Un audit de <strong>permis de construire.</strong></li>
            <li>Un recours <strong>gracieux.</strong></li>
            <li>Un recours <strong>contentieux.</strong></li>
          </ul>
          <div className="icon-text icon-text1">
               <img src="./img/parti.png" alt="Urbanisme" />
               <p>Toutes nos prestations sont <strong>faites maison,</strong> à partir de <strong>jurisprudences fraîches et locales,</strong> trouvées par nos soins. </p>
          </div>
        </div>
      </section>
      <section className="Honoraires Domaines-content1 Domaines-content2" style={{paddingTop:'0px'}}>
        
        <div className="container">
          <p>
            Par principe, les honoraires font l'objet d'une <strong>libre-négocation</strong> entre le client et l'avocat.
            <br /><br />
            Conformément à la déontologie de la profession et aux usages en la matière, les honoraires sont fixés
            au regard de <strong>plusieurs critères objectifs,</strong> tels que la complexité du dossier, le temps passé par l'avocat
            pour effectuer l'ensemble des prestations, le degré de spécialisation de l'avocat ou encore le caractère
            urgent du dossier confié.
            <br /><br />
            En toute circonstance, la <strong>convention d'honoraires</strong> est systématique.
            <br /><br />
            Ce contrat entre le client et l'avocat permet au premier de délimiter les contours du mandat qu'il entend
            confier à son avocat, et à ce dernier de s'assurer du règlement des honoraires correspondants aux
            prestations attendues.
            <br /><br />
            Si le client dispose d'une assurance de protection juridique, celle-ci peut couvrir tout ou partie des honoraires.
            <br /><br />
            Les honoraires peuvent être fixés au <strong>temps passé (180 €/h) ou forfaitairement</strong> en cas de prestation
            globale déterminée.
            <br /><br />
            Leur paiement peut être <strong>échelonné</strong> dans le temps, au fur et à mesure de l'état d'instruction du dossier.
            <br /><br />
            Chaque demande de règlement d'honoraires fait l'objet d'une <strong>facture</strong> mentionnant la nature de la
            prestation accomplie.
            <br /><br />
            En aucun cas l'intégralité des honoraires ne sera exigible avant l'accomplissement des prestations
            convenues.
            <br /><br />
            Un <strong>honoraire de résultat</strong> peut être fixé pour certains types de contentieux, notamment indemnitaires.
            <br/>En pareille hypothèse, l'honoraire de résultat ne peut pas excéder 8% du montant total des honoraires.
            <br /><br />
            A l'occasion du premier rendez-vous (forfait de 30 €), l'avocat évalue les <strong>besoins du client</strong> et procède à
            une analyse succincte du dossier en fonction des documents qui lui sont transmis.
            <br /><br />
            Si le client souhaite confier la défense de ses intérêts à l'avocat, ce dernier lui adresse un projet de
            convention d'honoraires reprenant les termes de sa mission et le montant des honoraires fixés.
            <br /><br />
            Une fois la convention datée et signée par le client et l'avocat, ce dernier entame les travaux nécessaires
            à la satisfaction des besoins du client.
            <br />
          </p>
        </div>
      </section>
      
      <footer>
      <hr></hr>
      <p>Lexmovice Avocat - Tous droits réservés</p>
      <p><Link to="/mentions-legales">Mentions légales</Link></p>
      <p>Une création de <a href="http://paullavergne.fr/" target="_blank" rel="noreferrer">Paul Lavergne</a> et <a href="https://margintoniks.fr" target="_blank" rel="noreferrer">Margintoniks</a></p> {/* À passer en ligne <a>quand la page en question sera crée */}
    </footer> 
    </>
  );
}

export default Honoraires;
