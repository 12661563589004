import React, {useEffect}  from 'react';
//import { Link, Route, BrowserRouter as Router} from 'react-router-dom';
import './../../sass/Page1.scss';
import {gsap} from 'gsap';
import Premier from './../../img/1.png'; 
import Deuxieme from './../../img/2.png'; 
import Troisieme from './../../img/3.png'; 
import Quatrieme from './../../img/4.png'; 
import Cinquieme from './../../img/5.png'; 

import FriseOrdi from './../../videos/horizontale2.mp4';
import DiplomeMobile from './../../videos/diplome-frise2.mp4';
import ExperienceMobile from './../../videos/exp-pro-frise2.mp4';

import {Link} from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

function Main() {

    // Apparition du paragraphe sur Pourquoi Lexmovice de la droite
    
    const Pourquoi_Lexmovice = React.createRef();
    useEffect(() => {
        gsap.from(Pourquoi_Lexmovice.current, {opacity:0, duration:1, x:-100})
        gsap.to(Pourquoi_Lexmovice.current, {opacity:1, duration:2, x:0})
    },[Pourquoi_Lexmovice]);

    var videos = document.getElementsByTagName("video");

    function checkScroll() {
        var fraction = 0.79; // Play when 80% of the player is visible.


        for(var i = 0; i < videos.length; i++) {

            var video = videos[i];
            
            if(!video.classList.contains('played')){

            var x = video.offsetLeft, y = video.offsetTop, w = video.offsetWidth, h = video.offsetHeight, r = x + w, //right
                b = y + h, //bottom
                visibleX, visibleY, visible;

                // visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));
                // visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset));

                // visible = visibleX * visibleY / (w * h);

                visible = y + fraction * h;


                if (visible < window.scrollY) {
                    video.play();
                    video.classList.add('played')
                }
            
            }

        }

    }

    window.addEventListener('scroll', checkScroll, false);
    window.addEventListener('resize', checkScroll, false);

    return (
        <main>
            <Helmet>
                <link rel="canonical" href={"https://lexmoviceavocat.fr"} />
            </Helmet>
            <h1>Un cabinet d'avocat compétent en droit public, urbanisme et fonction publique à Limoges</h1>
            <section>
                <article ref={Pourquoi_Lexmovice} id="Pourquoi_Lexmovice">
                    <h2>Pourquoi Lexmovice Avocat ?</h2>
                    <span>Voici 5 bonnes raisons de choisir Lexmovice :</span>
                    <p><img alt="Lexmovice avocat 1" src={Premier}/>Parce que Lexmovice répond à vos besoins spécifiques en <strong>droit administratif.</strong></p>
                    <p><img alt="Lexmovice avocat 2" src={Deuxieme}/>Parce que Lexmovice privilégie le <strong>dialogue</strong> et le règlement amiable au contentieux à tout prix.</p>
                    <p><img alt="Lexmovice avocat 3" src={Troisieme}/>Parce que Lexmovice est un cabinet d'avocat à <strong>taille humaine</strong> où l'information ne se perd pas
                    sous une pile de dossiers ou entre les différents acteurs.</p>
                    <p><img alt="Lexmovice avocat 4" src={Quatrieme}/>Parce que Lexmovice opte pour une <strong>gestion numérique et écologique des dossiers,</strong> facilitant
                    la transmission fluide des informations ainsi que leur conservation.</p>
                    <p><img alt="Lexmovice avocat 5" src={Cinquieme}/>Parce que Lexmovice dépoussière le droit et <strong>parle comme vous,</strong> sans locution latine à chaque
                    fin de phrase.</p>
                </article>
                <article id="Deontologie">
                    <p>Fort d'une expérience diversifiée et d'une orientation affirmée en <strong>droit public</strong> et <strong>urbanisme</strong>, <strong>Nicolas GRANGER </strong>
                    allie au quotidien la rigueur de la méthode juridique avec le pragmatisme acquis sur le terrain,
                    dans l'unique perspective de satisfaire vos besoins.</p>
                    <h2>Notre engagement déontologique</h2>
                    <ul>
                        <li>La <strong>relecture systématique</strong> par le client de tout projet décrit.</li>
                        <li>L'information garantie tout au long de la procédure.</li>
                        <li>La <strong>traduction du vocable juridique</strong> par un effort de vulgarisation afin d'en faciliter la compréhension pour le client.</li>
                        <li>Une <strong>convention d'honoraire</strong> pour chaque dossier, permettant au client de connaître le montant
                        exact des honoraires.</li>
                        <li>La <strong>confidentialité</strong> des correspondances entre avocats qui permet la résolution amiable des
                        différends et le <strong>secret professionnel</strong> auquel est soumis l'avocat, garantie pour le client vis-à-vis des tiers.</li>
                        <li>La tenue stricte des délais, par une <strong>organisation du travail</strong> rigoureuse qui ne laisse pas de place
                        à l'improvisation !</li>
                    </ul>
                </article>
                <article id="Domaines">
                    <h2>Nos domaines d'interventions</h2>
                    <p>Lexmovice Avocat traite actuellement 6 domaines d'interventions que sont :</p>
                    <ul>
                        <li>L'urbanisme</li>
                        <li>La responsabilité administrative</li>
                        <li>La domanialité publique</li>
                        <li>La fonction publique</li>
                        <li>La police administrative</li>
                        <li>Le droit au logement opposable</li>
                    </ul>
                    <p>Vous pouvez retrouver plus d'informations sur nos différentes domaines sur la page des <Link to="/domaines">domaines d'interventions</Link></p>
                </article>
                <article id="Frise-chrono">
                    <h2>L'expérience professionnelle de Lexmovice Avocat jusqu'au barreau de Limoges</h2>
                    <div className='mobile'>
                        <video keepplaying muted={true} playsInline>
                            <source src={DiplomeMobile} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <video keepplaying muted={true} playsInline>
                            <source src={ExperienceMobile} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className='ordi'>
                        <video keepplaying muted="true" playsInline>
                            <source src={FriseOrdi} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </article>
            </section>
            <footer>
                <hr></hr>
                <p>Lexmovice Avocat - Tous droits réservés</p>
                <p><Link to="/mentions-legales">Mentions légales</Link></p>
                <p>Une création de <a href="http://paullavergne.fr/" target="_blank" rel="noreferrer">Paul Lavergne</a> et <a href="https://margintoniks.fr" target="_blank" rel="noreferrer">Margintoniks</a></p> {/* À passer en ligne <a>quand la page en question sera crée */}
            </footer> 
        </main>
        
          
    )
}


export default Main;
