import './../../sass/Page2.scss';
import React from 'react';
import {Link} from 'react-router-dom';
import Logo from './../../img/logo-Lexmovice-Avocat.png'; 

function Menu() {
  const clickBtn = () =>{
    var burgerMenu = document.getElementById('burger-menu');
    var overlay = document.getElementById('menu');
    burgerMenu.classList.toggle("close");
    overlay.classList.toggle("overlay");
}

const Menu = React.createRef();
const MenuBurger = React.createRef();

  return (
        <div>
            <div ref={MenuBurger} id="burger-menu" onClick={clickBtn}>
                <span></span>
            </div>
            <Link to='/' id="logoLexmovice"><img alt="Logo de Lexmovice avocat " src={Logo}/></Link>
            <nav ref={Menu} id="menu">
                    <ul>
                        <li><Link to="/">Présentation</Link></li>
                        <li><Link to="/domaines">Domaines d'intervention</Link></li>
                        <li><a href="/plui-limoges/quest-ce-quun-plui">PLUi Limoges</a></li>
                        <li><Link to="/honoraires">Honoraires</Link></li>
                        <li><Link to="/contacts">Contacts</Link></li>
                    </ul>
            </nav>
        </div>
  );
}

export default Menu;