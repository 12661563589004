import React, {useEffect} from 'react';
import './../../sass/Page1.scss';
import './../../sass/menu.scss';
import './../../sass/Arrow.scss';
import {gsap} from 'gsap';
import {Link} from 'react-router-dom';


function Header() {
    // Animation de la première page Lexmovice | avocat
    const Lexmovice = React.createRef();
    const Avocat = React.createRef();
    const Trait = React.createRef();
    const Menu = React.createRef();
    const MenuBurger = React.createRef();
    const Arrow = React.createRef();

    useEffect(() => {
        if (window.matchMedia("(min-width: 1150px)").matches) {
            gsap.from(Trait.current, {width:"0%", y:350, duration:1})
            gsap.to(Trait.current, {width:"50%", y:350, duration:1})
            gsap.to(Trait.current, {width:"0%", duration:1, delay:2, opacity:0})
          } else {
            gsap.from(Trait.current, {width:"0%", y:"40vh", duration:1})
            gsap.to(Trait.current, {width:"50%", y:"40vh", duration:1})
          }
    },[Trait]);

    useEffect(() => {
        if (window.matchMedia("(min-width: 1150px)").matches) {
            gsap.from(Lexmovice.current, {y:185, opacity:0, duration:2})
            gsap.to(Lexmovice.current, {x:-250, delay:2})
        } else {
            gsap.from(Lexmovice.current, {y:0, opacity:0, duration:2})
            gsap.to(Lexmovice.current, {y:"30vh",opacity:1, duration:2})
        }
    }, [Lexmovice]);

    useEffect(() => {
        if (window.matchMedia("(min-width: 1150px)").matches) {
            gsap.from(Avocat.current, {y:-220, opacity:0, duration:2})
            gsap.to(Avocat.current, {x:300, delay:2})
        } else {
            gsap.from(Avocat.current, {y:0, opacity:0, duration:2})
            gsap.to(Avocat.current, {y:"-45vh",opacity:1,duration:2})
        }
    }, [Avocat]);

    useEffect(() => {
        gsap.from(Menu.current, {opacity:0, y:120, delay:3})
        gsap.to(Menu.current, {opacity:1, y:90, delay:3})
    }, [Menu]);

    useEffect(() => {
        if (window.matchMedia("(max-width:1150px)").matches) { 
            gsap.from(MenuBurger.current, {opacity:0, delay:1, duration:2})
            gsap.to(MenuBurger.current, {opacity:1, delay:1, duration:2})
        }
    }, [MenuBurger]);

    useEffect(() => {
        if (window.matchMedia("(max-width:1150px)").matches) { 
            gsap.from(Arrow.current, {opacity:0, delay:1, duration:2})
            gsap.to(Arrow.current, {opacity:1, delay:1, duration:2})
        }
    }, [Arrow]);


    const clickBtn = () =>{
        var burgerMenu = document.getElementById('burger-menu');
        var overlay = document.getElementById('menu');
        burgerMenu.classList.toggle("close");
        overlay.classList.toggle("overlay");
    }

    const pow = Math.pow;

    // The easing function that makes the scroll decelerate over time
    function easeOutQuart(x) {
    return 1 - pow(1 - x, 4);
    }

    const getElementPosition = (element) => element.getBoundingClientRect().top + document.documentElement.scrollTop;

    function animateScroll({ targetPosition, initialPosition, duration }) {
        let start;
        let position;
        let animationFrame;
      
        const requestAnimationFrame = window.requestAnimationFrame;
        const cancelAnimationFrame = window.cancelAnimationFrame;
      
        // maximum amount of pixels we can scroll
        const maxAvailableScroll =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight;
      
        const amountOfPixelsToScroll = initialPosition - targetPosition;
      
        function step(timestamp) {
          if (start === undefined) {
            start = timestamp;
          }
      
          const elapsed = timestamp - start;
      
          // this just gives us a number between 0 (start) and 1 (end)
          const relativeProgress = elapsed / duration;
      
          // ease out that number
          const easedProgress = easeOutQuart(relativeProgress);
      
          // calculate new position for every thick of the requesAnimationFrame
          position =
            initialPosition - amountOfPixelsToScroll * Math.min(easedProgress, 1);
      
          // set the scrollbar position
          window.scrollTo(0, position);
      
          // Stop when max scroll is reached
          if (
            initialPosition !== maxAvailableScroll &&
            window.scrollY === maxAvailableScroll
          ) {
            cancelAnimationFrame(animationFrame);
            return;
          }
      
          // repeat until the end is reached
          if (elapsed < duration) {
            animationFrame = requestAnimationFrame(step);
          }
        }
      
        animationFrame = requestAnimationFrame(step);
      }


    const scrollDown = ({ id = "Pourquoi_Lexmovice",  duration = 1500 }) => {
        console.log('test')
        // the position of the scroll bar before the user clicks the button
        const initialPosition = window.scrollY;

        const element = document.getElementById(id);

        if (!element) {
            // log error if the reference passed is invalid
            return;
        }

        animateScroll({
          targetPosition: getElementPosition(element),
          initialPosition,
          duration
        });
      };
    
    return (
        <header>
            <div className="background">
            <h1 ref={Lexmovice}>lexmovice</h1>
            <hr ref={Trait}/>
            <div ref={MenuBurger} id="burger-menu" onClick={clickBtn}>
                <span></span>
            </div>
            <nav ref={Menu} id="menu">
                    <ul>
                        <li><Link to="/">Présentation</Link></li>
                        <li><Link to="/domaines">Domaines d'intervention</Link></li>
                        <li><a href="/plui-limoges/quest-ce-quun-plui">PLUi Limoges</a></li>
                        <li><Link to="/honoraires">Honoraires</Link></li>
                        <li><Link to="/contacts">Contacts</Link></li>
                    </ul>
            </nav>
            <h2 ref={Avocat}>avocat</h2>
            <div ref={Arrow} id="wrapper">
				<a id="wrapper-inner" onClick={scrollDown} aria-label="Descendre vers la partie principale du site">
				  <div id="scroll-down">
					<span className="arrow-down">
					</span>
				  </div>
				</a>
			  </div>
            </div>
        </header>
    )

}


export default Header;
