import React from 'react';
import './../../sass/mentions.scss';
import Menu from './../Menu/menu';
import {Link} from 'react-router-dom';
//import {gsap} from 'gsap';




function MainLegales() {



    return (
        <div id="container-mention">
            <Menu/>
            <main id="mention">
                <br></br>
                <br></br>
                <h1>Mentions Légales</h1>
                                                                               
                                
<h2>Activité</h2>



<p class="has-text-align-left"><strong>Inscription au :</strong><br/>BARREAU DE LIMOGES le 5 octobre 2022</p>



<p class="has-text-align-left"><strong>Adresse :</strong>&nbsp;19 Boulevard Victor Hugo</p>



<p class="has-text-align-left"><strong>Code postal :</strong>&nbsp;87000</p>



<p class="has-text-align-left"><strong>Ville :</strong>&nbsp;LIMOGES</p>



<p class="has-text-align-left"><strong>Pays :</strong>&nbsp;FRANCE</p>



<h2>1 – Édition du site</h2>



<p><strong>En vertu de&nbsp;</strong><a href="https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000000801164#LEGIARTI000042038977"><strong>l’article 6 de la loi n° 2004-575 du 21 juin 2004</strong></a><strong>&nbsp;pour la confiance dans l’économie numérique, il est précisé aux utilisateurs du site internet&nbsp;https://www.lezennavocat.fr&nbsp;l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi:</strong></p>



<p><strong>Propriétaire du site :&nbsp;Nicolas GRANGER – Lexmovice Avocat&nbsp;– Contact :&nbsp;ngrangeravocat@gmx.fr&nbsp;06-19-51-85-52&nbsp;– Adresse :&nbsp;19 Boulevard Victor Hugo – 87000 Limoges – case palais n ° 311.</strong></p>



<p><strong>Identification de l’entreprise :&nbsp;Nicolas GRANGER – Lexmovice Avocat&nbsp;au capital social de&nbsp;€ – SIREN :&nbsp;848 386 272&nbsp;– RCS ou RM :&nbsp;– Adresse postale :&nbsp;19 Boulevard Victor Hugo – 87000 Limoges – case palais n ° 311</strong></p>



<p><strong>Directeur de la publication :&nbsp;Nicolas GRANGER&nbsp;– Contact :&nbsp;ngrangeravocat@gmx.fr.</strong></p>



<p><strong>Hébergeur : OVH – 19 Place Françoise Dorin, 75017 Paris</strong></p>



<p><strong>Autres contributeurs :&nbsp;<a href="http://paullavergne.fr/" target="_blank" rel="noreferrer">Paul Lavergne</a> et l’<a href="https://margintoniks.fr" data-type="URL" data-id="https://margintoniks.fr" target="_blank" rel="noreferrer noopener">agence web Margintoniks (Tours 37)</a></strong></p>



<h2>2 – Propriété intellectuelle et contrefaçons.</h2>



<p><strong>Nicolas GRANGER – Lexmovice Avocat&nbsp;est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur tous les éléments accessibles sur le site internet, notamment les textes, images, graphismes, logos, vidéos, architecture, icônes et sons.</strong></p>



<p><strong>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de&nbsp;Nicolas GRANGER – Lexmovice Avocat.</strong></p>



<p><strong>Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles&nbsp;</strong><a href="https://www.legifrance.gouv.fr/affichCodeArticle.do?idArticle=LEGIARTI000032655082&amp;cidTexte=LEGITEXT000006069414&amp;dateTexte=20160605"><strong>L.335-2 et suivants du Code de Propriété Intellectuelle</strong></a><strong>.</strong></p>



<h2>3 – Limitations de responsabilité.</h2>



<p><strong>Nicolas GRANGER – Lexmovice Avocat&nbsp;ne pourra être tenu pour responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site&nbsp;https://lexmoviceavocat.fr.</strong></p>



<p><strong>Nicolas GRANGER – Lexmovice Avocat&nbsp;décline toute responsabilité quant à l’utilisation qui pourrait être faite des informations et contenus présents sur&nbsp;https://lexmoviceavocat.fr.</strong></p>



<p><strong>Nicolas GRANGER – Lexmovice Avocat&nbsp;s’engage à sécuriser au mieux le site&nbsp;https://lexmoviceavocat.fr, cependant sa responsabilité ne pourra être mise en cause si des données indésirables sont importées et installées sur son site à son insu.</strong></p>



<p><strong>Des espaces interactifs (espace contact ou commentaires) sont à la disposition des utilisateurs.&nbsp;Nicolas GRANGER – Lexmovice Avocat&nbsp;se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données.</strong></p>



<p><strong>Le cas échéant,&nbsp;Nicolas GRANGER – Lexmovice Avocat&nbsp;se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie …).</strong></p>



<h2>4 – CNIL et gestion des données personnelles.</h2>



<p><strong>Conformément aux dispositions de&nbsp;</strong><a href="https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000000886460"><strong>la loi 78-17 du 6 janvier 1978 modifiée</strong></a><strong>, l’utilisateur du site&nbsp;https://lexmoviceavocat.fr&nbsp;dispose d’un droit d’accès, de modification et de suppression des informations collectées.</strong></p>



<h2>5 – Liens hypertextes et cookies</h2>



<p><strong>Le site&nbsp;https://lexmoviceavocat.fr&nbsp;contient des liens hypertextes vers d’autres sites et dégage toute responsabilité à propos de ces liens externes ou des liens créés par d’autres sites vers&nbsp;https://lexmoviceavocat.fr.</strong></p>



<p><strong>La navigation sur le site&nbsp;https://lexmoviceavocat.fr&nbsp;n’installe pas de cookie sur l’ordinateur de l’utilisateur.</strong></p>



<p><strong>Un «&nbsp;cookie&nbsp;» est un fichier de petite taille qui enregistre des informations relatives à la navigation d’un utilisateur sur un site. Les données ainsi obtenues permettent d’obtenir des mesures de fréquentation, par exemple.</strong></p>



<h2>6 – Droit applicable et attribution de juridiction.</h2>



<p><strong>Tout litige en relation avec l’utilisation du site&nbsp;https://lexmoviceavocat.fr&nbsp;est soumis au droit français. En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de juridiction aux tribunaux compétents de&nbsp;Nantes.</strong></p>



<h2>7 – Réclamation</h2>



<p>Conformément aux dispositions des articles L. 152-1 et suivants du Code de la Consommation, les clients ont la possibilité, en cas de litige avec un avocat, de recourir gratuitement au médiateur de la consommation de la profession d’avocat :&nbsp;Madame Carole PASCAREL</p>



<ul><li>par voie postale à l’adresse suivante :&nbsp;Médiateur de la consommation de la profession d’avocat, 180 boulevard Haussmann, 75008 Paris,</li><li>par courriel à l’adresse :&nbsp;<a rel="noreferrer noopener" href="mailto:mediateur-conso@mediateur-consommation-avocat.fr" target="_blank">mediateur-conso@mediateur-consommation-avocat.fr</a>,</li><li>directement par le site internet en remplissant le&nbsp;<a rel="noreferrer noopener" href="https://mediateur-consommation-avocat.fr/contacter-le-mediateur/" target="_blank">formulaire de saisie</a>.</li></ul>



<p>Les clients sont informés que la saisine du médiateur ne peut intervenir qu’après avoir tenté de résoudre le litige auprès de l’avocat par une réclamation écrite.</p>

<p>Vous disposez de la faculté d'introduire une réclamation auprès de la CNIL (<a target="_blank" rel="noreferrer" href="https://cnil.fr/fr/plaintes">www.cnil.fr/fr/plaintes</a>) concernant l'usage qui est fait de vos données personnelles par le responsable de traitement.</p>
            </main>
            <footer>
            <hr></hr>
            <p>Lexmovice Avocat - Tous droits réservés</p>
            <p><Link to="/mentions-legales">Mentions légales</Link></p>
            <p>Une création de <a href="http://paullavergne.fr/" target="_blank" rel="noreferrer">Paul Lavergne</a> et <a href="https://margintoniks.fr" target="_blank" rel="noreferrer">Margintoniks</a></p> {/* À passer en ligne <a>quand la page en question sera crée */}
            </footer>  
        </div>
          
    )
}


export default MainLegales; 
